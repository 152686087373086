.footer {
    color: white;
    padding: 0px;
    text-align: center;
    animation: colorChange 4s infinite;
}

@keyframes colorChange {
    0%, 100% { background-color: #ffbc0d; }
    50% { background-color: #f8c135; }
}

.locationSection {
    margin-bottom: 20px;
    padding-top: 20px;
}

.locationSection h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
    text-decoration: underline;
    text-decoration-color: #ea4335; /* Cor da linha */
    text-decoration-thickness: 3px; /* Espessura da linha (suportado em navegadores mais novos) */
    text-underline-offset: 5px; /* Espaço entre o texto e a linha */
}

.locationSection p {
    color: #0a1f44;
    font-weight: 600;
    font-size: 0.9rem;
    margin-bottom: 10px;
}

.map {
    border: 0;
    width: 100%;
    height: 300px;
    margin-top: 10px;
}

.contactButtons {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin: 20px 0;
}

.whatsappButton,
.phoneButton,
.emailButton {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    color: #0a1f44;
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
    font-size: 1rem;
    transition: background-color 0.3s ease;
}

.whatsappButton:hover {
    background-color: #25D366;
    color: white;
}

.phoneButton:hover {
    background-color: #555;
    color: white;
}

.emailButton:hover {
    background-color: #ea4335;
    color: white;
}

.socialMedia {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin: 20px 0;
    padding: 20px;
    padding-bottom: 30px;
}

.socialMedia a {
    color: #ec3237;
    font-size: 1.5rem;
    transition: color 0.3s ease;
}

.socialMedia a:hover {
    color: #a02024;
}

.institutionInfo {
    margin-top: 20px;
    padding: 20px;
}

.institutionInfo h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
    text-decoration: underline;
    text-decoration-color: #ea4335; /* Cor da linha */
    text-decoration-thickness: 3px; /* Espessura da linha (suportado em navegadores mais novos) */
    text-underline-offset: 5px; /* Espaço entre o texto e a linha */
}

.institutionInfo p {
    color: #0a1f44;
    font-weight: 600;
    font-size: 0.9rem;
    margin: 5px 0;
}

.institutionInfo a {
    color: #ec3237;
    text-decoration: none;
    font-weight: 700;
}

.institutionInfo a:hover {
    text-decoration: underline;
}

@keyframes float {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-10px);
    }
    100% {
        transform: translateY(0);
    }
}

.returnTop {
    cursor: pointer;
    margin: 20px auto;
    font-size: 2rem;
    color: white;
    background-color: #ec3237;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease;
    animation: float 2s ease-in-out infinite; /* Adiciona a animação de flutuação */
}

.returnTop:hover {
    background-color: #555;
}

.arrowIcon {
    font-size: 1.5rem;
    color: #f5b041; /* Cor da seta */
}

@media(max-width:800px) {
    .contactButtons {
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;
        gap: 40px;
        margin: 20px 0;
        padding-bottom: 10px;
    }
}
