.propostaContainer {
    color: #333;
    line-height: 1.8;
    background-color: #fdfdfd;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.content {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
}

.section {
    margin-bottom: 30px;
}

.section p {
    margin: 0;
    text-align: justify;
    font-size: 1.1rem;
    color: #4a4a4a;
}

.section strong {
    color: #d32f2f;
}

.signature {
    margin-top: 40px;
    text-align: right;
    font-style: italic;
    color: #d32f2f;
    text-decoration: underline;
}
