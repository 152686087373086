/* Estilo geral do painel administrativo */
.adminContainer {
    display: flex;
    height: 100vh;
    font-family: 'Roboto', sans-serif;
    color: #2D3436;
    background-color: #F5F7FA;
}

/* Barra lateral */
.sidebar {
    width: 240px;
    background-color: #1E272E;
    color: #FFFFFF;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 4px 0 10px rgba(0, 0, 0, 0.1);
}

.title {
    font-size: 1.8em;
    margin-bottom: 2em;
    color: #ECF0F1;
    font-weight: 600;
    text-align: center;
}

.nav {
    width: 100%;
    text-align: left;
}

.nav ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.navButton {
    width: 100%;
    display: flex;
    align-items: center;
    background-color: transparent;
    border: none;
    color: #BDC3C7;
    padding: 12px 20px;
    font-size: 1em;
    font-weight: 500;
    text-align: left;
    cursor: pointer;
    transition: all 0.3s ease;
    border-radius: 8px;
    margin-bottom: 0.5em;
}

.navButton:hover {
    background-color: #576574;
    color: #FFFFFF;
    transform: translateX(5px);
}

.navButton:focus {
    outline: none;
    background-color: #10AC84;
    color: #FFFFFF;
}

.iconNoticias::before,
.iconProjetos::before {
    content: '•'; /* Ícones simulados */
    font-size: 1.2em;
    margin-right: 10px;
    color: #10AC84;
}

.iconNoticias::before {
    color: #EE5253; /* Cor específica para Notícias */
}

.iconProjetos::before {
    color: #FFD32A; /* Cor específica para Projetos */
}

/* Área de conteúdo */
.contentArea {
    flex: 1;
    padding: 30px;
    background-color: #FFFFFF;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    margin: 20px;
}

/* Botão de menu hamburguer */
.hamburgerButton {
    display: none; /* Oculto em telas maiores */
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: #1E272E;
    color: #FFFFFF;
    border: none;
    font-size: 1.5em;
    cursor: pointer;
    z-index: 10;
}



/* Ajustes para telas menores */
@media (max-width: 768px) {
    .adminContainer {
        flex-direction: column; /* Alinha os elementos em coluna */
        height: auto; /* Ajusta a altura automaticamente */
    }

    .sidebar {
        width: 100%; /* A barra lateral ocupa toda a largura */
        padding: 10px;
        box-shadow: none; /* Remove a sombra lateral */
        margin-bottom: 20px;
    }

    .navButton {
        font-size: 0.9em; /* Reduz o tamanho da fonte */
        padding: 10px 15px;
    }

    .contentArea {
        padding: 15px; /* Reduz o espaçamento interno */
        margin: 0;
        border-radius: 0; /* Remove o arredondamento para telas pequenas */
        box-shadow: none; /* Remove a sombra para simplicidade */
    }

    
    .hamburgerButton {
        display: block;
    }

    .sidebar {
        position: absolute;
        width: 200px;
        height: 100%;
        z-index: 9;
        transition: transform 0.3s ease-in-out;
    }

    .adminContainer {
        position: relative;
        padding-top: 60px; /* Para acomodar o botão do menu */
    }
}
