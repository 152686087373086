/* Container principal */
.projetoContainer {
    background-color: #f7f9fc;
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Lista de projetos */
.projetosLista {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 20px;
    max-width: 80%;
    border-bottom: 1px solid #949494;
    padding-bottom: 10px;
    margin-bottom: 30px;
}

/* Item de projeto */
.projetoItem {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.projetoItem:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

/* Imagem do projeto */
.projetoImagem {
    width: 100%;
    height: auto;
    max-height: 200px;
    object-fit: cover;
    border-radius: 5px;
    margin-bottom: 15px;
}

/* Placeholder de imagem */
.projetoImagemPlaceholder {
    width: 100%;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #e0e0e0;
    border-radius: 5px;
    margin-bottom: 15px;
}

.iconPlaceholder {
    color: #b0b0b0;
    font-size: 3em;
}

/* Título do projeto */
.projetoTitulo {
    font-size: 1.5em;
    font-weight: bold;
    color: #1c2a5b;
    margin: 10px 0 5px;
}

/* Subtítulo do projeto */
.projetoSubtitulo {
    font-size: 1.2em;
    color: #616161;
    margin-bottom: 10px;
}

/* Data do projeto */
.projetoData {
    font-size: 0.9em;
    color: #9e9e9e;
    margin-bottom: 10px;
}

.projetoDescricao {
    font-size: 1em;
    color: #424242;
    margin-bottom: 10px;
    line-height: 1.6;
}

.readMore {
    color: #212E3F;
    cursor: pointer;
    font-weight: bold;
    margin-left: 5px;
    transition: 0.1s;
}

.readMore:hover {
    text-decoration: underline;
    color: #f7c32e; /* Um tom mais escuro no hover */
}


/* Texto completo */
.projetoTexto {
    font-size: 0.9em;
    color: #666666;
    line-height: 1.6;
    margin-bottom: 10px;
}

/* Botão Ver Mais */
.verMaisButton {
    background-color: #1c2a5b;
    color: #ffffff;
    border: none;
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9em;
    transition: background-color 0.3s ease;
    margin-top: 10px;
}

.verMaisButton:hover {
    background-color: #142045;
}

/* Botão Ver Mais */
.saibaMaisButton {
    background-color: #1c2a5b;
    color: #ffffff;
    border: none;
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9em;
    transition: background-color 0.3s ease;
    margin-top: 10px;
}

.saibaMaisButton:hover {
    background-color: #142045;
}

@media(max-width:700px) {
    .projetosLista {
        max-width: 95%;
    }
    
}