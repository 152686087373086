.noticiaFull {
    width: 100%;
    background-color: #f3f6fa;
    padding-top: 40px;
    padding-bottom: 40px;
    margin-top: 60px;
}

.noticiaContainer {
    background-color: #ffffff;
    padding: 40px;
    max-width: 900px;
    margin: 0 auto;
    border-radius: 8px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
    text-align: left;
}

.noticiaTitulo {
    font-size: 2.4em;
    font-weight: bold;
    color: #1c3b5f;
    margin-bottom: 15px;
    text-align: center;
    border-bottom: 3px solid #ffa500;
    padding-bottom: 10px;
}

.noticiaData {
    font-size: 0.9em;
    color: #888888;
    margin-bottom: 20px;
    text-align: center;
    font-style: italic;
}

.noticiaImagemContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
}

.noticiaImagem {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
}

.noticiaImagem:hover {
    transform: scale(1.05);
}

.noticiaImagemPlaceholder {
    width: 100%;
    max-width: 600px;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #e0e4e8;
    border-radius: 8px;
    box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.1);
}

.iconPlaceholder {
    color: #b0b6bb;
    font-size: 3em;
}

.noticiaSubtitulo {
    font-size: 1.6em;
    color: #333333;
    margin-bottom: 15px;
    text-align: center;
    font-weight: 500;
}

.noticiaDescricao {
    font-size: 1.1em;
    color: #444444;
    margin-bottom: 25px;
    background: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
}

.noticiaTexto {
    font-size: 1.1em;
    color: #2e2e2e;
    line-height: 1.8;
    padding: 20px;
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
}

.noticiaVideoContainer {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.noticiaVideo {
    width: 100%;
    max-width: 800px;
    height: 450px;
    border: none;
    border-radius: 8px;
    box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.1);
}

.loading, .notFound {
    font-size: 1.3em;
    color: #666;
    text-align: center;
    margin: 50px auto;
}


@media (max-width:850px) {
    .noticiaContainer {
        background-color: #ffffff;
        padding: 40px;
        max-width: 900px;
        margin: 0 auto;
        border-radius: 0px;
        box-shadow: none;
        text-align: left;
    }

    .noticiaFull {
        width: 100%;
        background-color: #f3f6fa;
        padding-top: 00px;
        padding-bottom: 00px;
        margin-top: 60px;
    }
    
}