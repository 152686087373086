/* Container Geral */
.container {
    max-width: 1000px;
    margin: 20px auto;
    padding: 30px;
    background-color: #f8fafc;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

/* Cabeçalho */
.header {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 20px;
}

h2 {
    font-size: 1.8em;
    color: #333;
}

/* Ações do Cabeçalho */
.actionsHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* Campo de Busca */
.searchInput {
    padding: 8px 12px;
    border: 1px solid #ced4da;
    border-radius: 5px;
    font-size: 0.9em;
    width: 250px;
    transition: box-shadow 0.2s;
}

.searchInput:focus {
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.25);
    outline: none;
    border-color: #007bff;
}

/* Botão de Adição */
.addButton {
    padding: 10px 15px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 0.9em;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.addButton:hover {
    background-color: #0056b3;
}

/* Alternância entre Modos */
.viewToggle {
    display: flex;
    justify-content: center;
    margin: 15px 0;
    gap: 15px;
}

.viewButton {
    background: none;
    border: 2px solid #ccc;
    border-radius: 50%;
    padding: 12px;
    cursor: pointer;
    transition: border-color 0.3s, background-color 0.3s, transform 0.2s;
}

.viewButton:hover {
    border-color: #007bff;
    transform: scale(1.1);
}

.viewButton.active {
    border-color: #007bff;
    background-color: #e7f0ff;
}

/* Lista de Banners */
.bannersList {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
    display: flex;
    flex-direction: column;
}

/* Item do Banner */
.bannerItem {
    padding: 20px;
    background-color: white;
    border: 1px solid #e5e7eb;
    border-radius: 4px;
    border: 1px solid #ccc;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
    display: flex;
    flex-direction: column;
    transition: box-shadow 0.3s ease;
}

.bannerItem:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.08);
}

/* Conteúdo do Banner */
.bannerContent {
    text-align: center;
    margin-top: 10px;
}

.bannerTitle {
    font-size: 1.2em;
    color: #333;
    margin-bottom: 10px;
}

.actions {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 15px;
}

/* Botões de Ação */
.editButton,
.deleteButton {
    padding: 8px 16px;
    font-size: 0.9em;
    font-weight: 600;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.editButton {
    background-color: #17a2b8;
}

.deleteButton {
    background-color: #dc3545;
}

.editButton:hover {
    background-color: #138496;
}

.deleteButton:hover {
    background-color: #c82333;
}

/* Container da Imagem */
.imageContainer {
    position: relative;
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    background-color: #f0f0f0;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: max-height 0.3s ease, border-radius 0.3s ease;
    background-color: blue;
    display: flex;
    justify-content: center;
}

/* Estilos para Desktop e Mobile */
.imageContainer.desktop {
    aspect-ratio: 16 / 9;
    max-height: 400px;
}

.imageContainer.mobile {
    aspect-ratio: 9 / 16;
    max-height: 600px;
    width: 200px;
    display: flex;
    justify-content: center; /* Centraliza horizontalmente */
    align-items: center; /* Centraliza verticalmente */
    margin: 0 auto; /* Garante que o container fique no centro */
}

/* Estilo das Imagens */
.imageDesktop,
.imageMobile {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
    opacity: 0;
    visibility: hidden;
}

/* Imagens Ativas */
.imageDesktop.active,
.imageMobile.active {
    opacity: 1;
    visibility: visible;
}

/* Responsividade */
@media (max-width: 768px) {
    .imageContainer {
        max-height: 300px;
    }

    .imageDesktop,
    .imageMobile {
        max-height: 300px;
    }

    .bannerItem {
        display: flex;
        flex-direction: column;
        align-items: center; /* Centraliza o conteúdo dentro do banner */
    }

    .bannerContent {
        text-align: center;
    }
}
