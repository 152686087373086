.videoContainer {
    background-color: #f4f9ff;
    padding: 20px;
    border-radius: 8px;
    max-width: 90%;
    margin: 0 auto;
    text-align: center;
    color: #333;
    padding-top: 40px;
    padding-bottom: 60px;
    overflow-x: hidden;
}

.title {
    font-size: 2em;
    font-weight: bold;
    margin-bottom: 20px;
}

.videos {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 30px;
}

.videoWrapper {
    flex: 1;
    max-width: 45%;
    aspect-ratio: 16/9;
    /* Para manter a proporção dos vídeos */
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.infoText {
    font-size: 1em;
    color: #ff0000;
    /* Vermelho para destaque */
    font-weight: bold;
    margin: 20px 0;
}

.actionButton {
    background-color: #28a745;
    /* Verde para o botão */
    color: white;
    padding: 12px 24px;
    font-size: 1.1em;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.actionButton:hover {
    background-color: #218838;
    /* Escurece o verde ao passar o mouse */
}


@media (max-width: 1024px) {
    .actionButton {
        padding: 10px 20px;
        font-size: 1em;
    }
}

/* Responsividade para telas menores */
@media (max-width: 768px) {
    .videos {
        flex-direction: column;
        gap: 15px;
    }

    .videoWrapper {
        max-width: 100%;
    }

    .title {
        font-size: 1.5em;
    }

    .infoText {
        font-size: 0.9em;
    }

    .actionButton {
        padding: 10px 20px;
        font-size: 0.95em;
    }
}

@media (max-width: 480px) {
    .actionButton {
        padding: 10px 20px;
        font-size: 0.85em;
    }
}