.contatoContainer {
    padding: 60px 20px;
    font-family: 'Roboto', sans-serif;
    color: #2E2E2E;
    background-color: transparent;
    max-width: 1200px;
    margin: 0 auto;
    border-radius: 10px;
}

.pageDescription {
    font-size: 1.8em;
    color: #ea4335;
    text-align: center;
    margin-bottom: 40px;
    font-weight: 600;
    line-height: 1.6;
}

.sectorsContainer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 25px;
}

.setorCard {
    background-color: #ffffff;
    padding: 25px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    border: 1px solid #ddd;
}

.setorCard:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.setorTitle {
    font-size: 1.8em;
    color: #444;
    margin-bottom: 15px;
    font-weight: bold;
    text-align: center;
}

.setorDescription {
    font-size: 1.1em;
    color: #666;
    margin-bottom: 20px;
    text-align: center;
}

.contactInfo {
    font-size: 1em;
    color: #333;
    margin-bottom: 15px;
    text-align: center;
}

.contactInfo a {
    color: #0056B3;
    text-decoration: none;
    font-weight: bold;
}

.contactInfo a:hover {
    text-decoration: underline;
    color: #003D80;
}

.contactInfowhatzap {
    font-size: 1em;
    color: #333;
    margin-bottom: 15px;
    text-align: center;
}

.contactInfowhatzap a {
    color: white;
    text-decoration: none;
    font-weight: bold;
}

.contactInfowhatzap a:hover {
    text-decoration: underline;
    color: #003D80;
}

.whatsappButton {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    background-color: #25D366;
    color: #FFFFFF;
    text-decoration: none;
    padding: 12px 20px;
    border-radius: 5px;
    font-weight: bold;
    font-size: 1em;
    transition: background-color 0.3s ease, transform 0.2s ease;
    margin-top: 10px;
    text-align: center;
}

.whatsappButton:hover {
    background-color: #1EBE5A;
    transform: translateY(-3px);
}

.whatsappButton:visited,
.whatsappButton:active {
    color: #FFFFFF;
}

.whatsappIcon {
    font-size: 1.5em;
}

/* Responsividade */
@media (max-width: 768px) {
    .pageDescription {
        font-size: 1.5em;
        font-weight: 600;
        padding: 0 10px;
    }

    .sectorsContainer {
        grid-template-columns: 1fr; /* Coluna única para telas menores */
    }

    .setorCard {
        padding: 20px;
        margin: 0 auto;
    }

    .setorTitle {
        font-size: 1.6em;
    }

    .setorDescription {
        font-size: 1em;
    }

    .whatsappButton {
        font-size: 0.9em;
        padding: 10px 15px;
    }

    .whatsappIcon {
        font-size: 1.3em;
    }
}

@media (max-width: 480px) {
    .pageDescription {
        font-size: 1.3em;
        font-weight: 600;
    }

    .setorCard {
        padding: 15px;
    }

    .setorTitle {
        font-size: 1.4em;
    }

    .setorDescription {
        font-size: 0.9em;
    }

    .whatsappButton {
        padding: 8px 10px;
        font-size: 0.85em;
    }

    .whatsappIcon {
        font-size: 1.2em;
    }
}
