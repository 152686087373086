/* Fundo escuro sobre a tela */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

/* Janela do popup */
.popup {
    width: 400px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    overflow: hidden;
    animation: fadeIn 0.3s ease;
}

/* Cabeçalho do popup */
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    background-color: #2C3E50;
    color: #FFFFFF;
}

/* Título */
.title {
    font-size: 1.2em;
    margin: 0;
    color: #ECF0F1;
}

/* Botão de fechamento */
.closeButton {
    background: transparent;
    border: none;
    color: #ECF0F1;
    font-size: 1.2em;
    cursor: pointer;
    transition: color 0.2s ease;
}

.closeButton:hover {
    color: #E74C3C;
}

/* Conteúdo do popup */
.content {
    padding: 20px;
    font-size: 1em;
    color: #333;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 450px;
}
