/* TrabalheConosco.module.css */

:root {
  --primary-color: #007bff;
  --primary-hover: #0056b3;
  --background-color: #f9f9f9;
  --border-color: #ddd;
  --text-color: #333;
  --heading-color: #555;
  --label-color: #444;
  --success-color: #28a745;
  --font-family: "Roboto", sans-serif;
  --box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.container {
  max-width: 1000px;
  margin: 0 auto;
  margin-top: 40px;
  margin-bottom: 40px;
  padding: 30px;
  background-color: white;
  border-radius: 12px;
  box-shadow: var(--box-shadow);
  transition: transform 0.3s ease;
  font-family: var(--font-family);
}

.container:hover {
  transform: scale(1.01);
}

.header {
  text-align: center;
  margin-bottom: 30px;
}

.header h3 {
  font-size: 28px;
  color: var(--heading-color);
  margin-bottom: 10px;
  font-weight: bold;
}

.header p {
  font-size: 18px;
  color: #666;
  line-height: 1.5;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.inputGroup {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.inputGroup label {
  font-weight: 600;
  font-size: 16px;
  color: var(--label-color);
}

.input,
.textarea {
  width: 96%;
  padding: 15px;
  border: 1px solid var(--border-color);
  border-radius: 6px;
  font-size: 16px;
  outline: none;
  background-color: #fff;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.input:focus,
.textarea:focus {
  border-color: var(--primary-color);
  box-shadow: 0 0 8px rgba(0, 123, 255, 0.3);
}

.textarea {
  height: 140px;
  resize: none;
}

.button {
  background-color: var(--primary-color);
  color: white;
  padding: 14px 22px;
  border: none;
  border-radius: 6px;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  text-align: center;
  box-shadow: var(--box-shadow);
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.button:hover {
  background-color: var(--primary-hover);
  transform: translateY(-2px);
}

.successMessage {
  text-align: center;
  margin-top: 50px;
}

.successMessage h1 {
  color: var(--success-color);
  font-size: 34px;
  font-weight: bold;
  margin-bottom: 20px;
}

.successMessage p {
  font-size: 20px;
  color: var(--text-color);
  line-height: 1.6;
}

/* Responsividade - Telas Médias */
@media (max-width: 768px) {
  .container {
    padding: 20px;
    border-radius: 6px;
  }

  .input,
  .textarea {
    width: 94%;
  }

  .header h3 {
    font-size: 24px;
  }

  .header p {
    font-size: 16px;
  }

  .button {
    padding: 12px 18px;
    font-size: 16px;
  }

  .textarea {
    height: 120px;
  }
}

/* Responsividade - Telas Pequenas */
@media (max-width: 480px) {
  .container {
    padding: 15px;
    border-radius: 0px;
  }

  .header h3 {
    font-size: 20px;
  }

  .header p {
    font-size: 14px;
  }

  .button {
    padding: 10px;
    font-size: 14px;
  }

  .input,
  .textarea {
    font-size: 14px;
    padding: 12px;
  }

  .successMessage h1 {
    font-size: 28px;
  }

  .successMessage p {
    font-size: 16px;
  }
}
