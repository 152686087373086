/* Header */
.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffff;
    padding: 10px 20px;
    color: #fff;
    position: fixed;
    width: 100%;
    top: 0;
    transition: transform 0.3s ease-in-out, background-color 0.3s ease;
    z-index: 1000;
    border-bottom: 3px solid #ffd700;
    box-sizing: border-box;
}

/* Mostrar e esconder header */
.show {
    transform: translateY(0);
}

.hide {
    transform: translateY(-100%);
}

/* Logo */
.logoContainer {
    display: flex;
    align-items: center;
    margin-left: 20px;
}

.logo {
    height: 60px;
    max-width: 100%;
    margin-right: 15px;
}

.matriculasAbertas {
    background-color: #ffbc0d;
    color: #292929;
    padding: 11px 18px;
    font-size: 15px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    font-weight: 500;
    transition: 0.4s;
}

.matriculasAbertas:hover {
    background-color: #c7920b;
}

/* Navigation Links */
.navLinksContainer {
    min-height: 30px;
    display: flex;
    gap: 20px;
    flex: 1;
    justify-content: center;
    padding: 0 20px;
    box-sizing: border-box;
}

.navLink {
    color: #292929;
    text-decoration: none;
    font-size: 1rem;
    font-weight: 700;
    padding: 5px 10px;
    border-radius: 4px;
    transition: color 0.3s, background-color 0.3s;
}

.navItem {
    position: relative; 
    cursor: pointer;
    padding-top: 10px;
    padding-bottom: 10px;
}

/* Estilo do submenu */
.submenu {
    position: absolute;
    top: 100%; /* Posiciona diretamente abaixo do item */
    left: 0;
    background-color: #ffffff;
    padding: 10px;
    border-radius: 4px;
    display: none; /* Esconde inicialmente */
    flex-direction: column;
    gap: 8px;
    z-index: 1001;
    min-width: 180px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
    font-weight: 700;
}

.navItem:hover .submenu {
    display: flex; /* Exibe o submenu ao passar o mouse */
}

.submenuLink {
    color: #292929;
    text-decoration: none;
    padding: 8px;
    border-radius: 4px;
    transition: background-color 0.3s;
}

.submenuLink:hover {
    background-color: #ec3238e0;
}

/* Estilo para hover dos itens do menu */
.navItem:hover {
    background-color: #dadada69;
    border-radius: 5px;
    color: #ffd700;
}

/* Ícone para dropdown */
.arrowIcon {
    margin-left: 5px;
    transition: transform 0.3s ease;
    color: #ffd700;
}

.arrowOpen {
    transform: rotate(180deg);
}

/* Wrapper e Ícone do Menu (Hambúrguer) */
.menuWrapper {
    display: flex;
    align-items: center;
    gap: 15px;
}

.menuIcon {
    font-size: 2rem;
    color: #ffd700;
    cursor: pointer;
}

/* Botão de Matrícula */
.enrollButton {
    background-color: #ffd700;
    color: #5a5400;
    padding: 8px 16px;
    border: none;
    border-radius: 20px;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    margin-right: 30px;
    transition: background-color 0.3s, transform 0.3s;
}

.enrollButton:hover {
    background-color: #ffcc33;
    color: #7c7400;
    transform: scale(1.05);
}

/* Modal para dispositivos móveis */
.menuOpen {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 9999;
    overflow: hidden;
}

/* Conteúdo do Modal */
.modalContent {
    background-color: #ffffff;
    padding: 30px;
    width: 90%;
    max-width: 500px;
    border-radius: 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 20px;
    color: #ff0000;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.5);
}

.closeButton {
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 1.5rem;
    color: #ffd700;
    cursor: pointer;
}

/* Submenu dentro do Modal */
.modalSubmenu {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    gap: 10px;
    padding-left: 20px;
    border-left: 2px solid #ffd700;
    color: #292929;
}

.modalSubmenuLink {
    color: #292929;
    text-decoration: none;
    padding: 8px;
    border-radius: 4px;
    transition: background-color 0.3s;
}

.modalSubmenuLink:hover {
    background-color: #555;
    color: #fff;
}

/* Responsividade para dispositivos móveis */
@media (max-width: 800px) {
    .navLinksContainer {
        display: none;
    }
    .menuIcon {
        display: block;
    }
    .logoContainer {
        margin-left: 10px;
    }
    .logo {
        width: 50px;
        height: 50px;
    }
}
