.heroContainer {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-color: #fffeb9;
  margin-top: 70px;
}

.heroSlide {
  position: absolute;
  top: 0;
  left: 100%;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  opacity: 0;
  transition: all 1s ease; /* Transição suave */
}

.heroSlide.active {
  left: 0;
  opacity: 1;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.479); /* Película preta */
  display: flex;
  justify-content: center;
  align-items: center;
}

.textContainer {
  text-align: center;
  color: white;
  max-width: 80%;
  padding: 20px;
}

.subtitle {
  font-size: 1.2rem;
  margin-bottom: 10px;
  opacity: 0.8;
}

.title {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 15px;
}

.heroButton {
  padding: 10px 20px;
  background-color: #ec3237;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.heroButton:hover {
  background-color: #ca9610;
}

.spinnerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #fffeb9;
}

@media (max-width: 800px) {
  .heroContainer {
    height: 90vh;
  }
  .overlay {
    display: flex;
    justify-content: start;
    align-items: center;
  }
  .textContainer {
    max-width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .textContainer .title {
    font-size: 38px;
  }
  .textContainer .subtitle {
    margin-bottom: -20px;
  }
}
