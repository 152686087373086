.sobreContainer {
    background-color: #f4f9ff;
    color: #333;
    padding: 20px;
    border-radius: 8px;
    max-width: 80%;
    margin: 0 auto;
    text-align: left;
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    max-width: 1200px;
}

.title {
    font-size: 2em;
    font-weight: bold;
    margin-bottom: 10px;
    color: #212e3f;
    text-align: center;
}

.subtitle {
    font-size: 1.5em;
    /* Aumentado para subtítulo em telas maiores */
    color: #333;
    font-weight: 600;
    text-align: center;
    margin-bottom: 30px;
}

.description {
    font-size: 1.1em;
    /* Levemente maior para telas grandes */
    color: #333;
    margin-bottom: 5px;
    text-align: center;
}

.buttonContainer {
    text-align: center;
    margin-top: 30px;
}

.buttonInfo {
    font-size: 1em;
    color: #ff0000;
    /* Texto em vermelho para destaque */
    font-weight: bold;
    margin-bottom: 20px;
}

.actionButton {
    background-color: #28a745;
    /* Verde para o botão */
    color: white;
    padding: 12px 24px;
    font-size: 1.1em;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.actionButton:hover {
    background-color: #218838;
    /* Escurece o verde ao passar o mouse */
}

/* Responsividade para telas médias (laptops e tablets) */
@media (max-width: 1024px) {
    .sobreContainer {
        max-width: 90%;
        /* Ajusta para ocupar a maioria da largura da tela */
        padding: 20px;
    }

    .title {
        font-size: 2.2em;
        font-weight: 600;
        /* Reduz levemente o peso */
    }

    .subtitle {
        font-size: 1.3em;
        font-weight: 500;
    }

    .description {
        font-size: 1em;
    }

    .buttonInfo {
        font-size: 0.95em;
    }

    .actionButton {
        padding: 10px 20px;
        font-size: 1em;
    }
}

/* Responsividade para telas menores (tablets em retrato e smartphones grandes) */
@media (max-width: 768px) {
    .sobreContainer {
        max-width: 100%;
        /* Ocupar 100% da tela */
        padding: 15px;
        padding-bottom: 70px;
    }

    .subtitle {
        font-size: 1.1em;
    }

    .description {
        font-size: 0.95em;
    }

    .buttonInfo {
        font-size: 0.85em;
    }

    .actionButton {
        padding: 10px 20px;
        font-size: 0.95em;
    }
}

/* Responsividade para telas pequenas (smartphones) */
@media (max-width: 480px) {
    .subtitle {
        font-size: 1em;
        margin-bottom: 15px;
    }

    .description {
        font-size: 0.85em;
    }

    .buttonInfo {
        font-size: 0.8em;
    }

    .actionButton {
        padding: 10px 20px;
        font-size: 0.85em;
    }
}