.form {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.input, .textarea {
    width: 93%;
    padding: 10px;
    font-size: 1em;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: none;
}

.textarea {
    min-height: 100px;
}

.button {
    padding: 10px;
    background-color: #1ABC9C;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1em;
    transition: background-color 0.3s;
}

.button:hover {
    background-color: #16A085;
}

.previewImage {
    width: 100%;
    max-width: 300px; /* Limita a largura máxima da imagem */
    height: auto; /* Mantém a proporção da imagem */
    border-radius: 8px; /* Bordas arredondadas */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Sombra para destacar a imagem */
    margin-top: 10px; /* Espaço entre a imagem e o input */
    object-fit: cover; /* Garante que a imagem se ajuste bem dentro do contêiner */
}

.fileUpload {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
}

.fileUpload label {
    font-size: 0.9em;
    font-weight: bold;
    color: #333;
}

.fileUpload input {
    font-size: 0.9em;
}
