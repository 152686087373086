/* FloatWhatzapButton.module.css */
@keyframes float {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-5px); /* Sobe 5px no meio da animação */
    }
}

.floatButton {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 170px;
    height: auto;
    background-color: #25d36500; 
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    animation: float 3s ease-in-out infinite;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.floatButton:hover {
    transform: scale(1.1);
}

.icon {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* Responsividade para telas menores */
@media (max-width: 480px) {
    .floatButton {
        width: 140px;
        bottom: 15px;
        right: 15px;
    }
}
