.noticiasContainer {
    background: linear-gradient(120deg, #f3a41c, #f7c32e, #f3a41c);
    background-size: 200% 200%;
    animation: gradientMove 3s ease-in-out infinite;
    padding: 60px 20px;
    max-width: 100%;
    text-align: center;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
}

@keyframes gradientMove {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.sectionTitle {
    font-size: 2.5em;
    color: #FFFFFF;
    font-weight: bold;
    margin-bottom: 30px;
    text-decoration: underline;
    text-decoration-color: #ea4335;
    text-decoration-thickness: 3px;
    text-underline-offset: 5px;
}

.noticiasGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
    z-index: 2;
}

.noticiaCard {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease;
}

.noticiaCard:hover {
    transform: scale(1.05);
}

.noticiaImage {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.noticiaImagePlaceholder {
    width: 100%;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #e0e0e0;
}

.iconPlaceholder {
    color: #b0b0b0;
    font-size: 3em;
}

.noticiaContent {
    padding: 20px;
}

.noticiaTitle {
    font-size: 1.5em;
    color: #333;
    margin-bottom: 10px;
    font-weight: bold;
}

.noticiaDate {
    font-size: 0.9em;
    color: #777;
    margin-bottom: 15px;
}

.noticiaDescription {
    font-size: 1em;
    color: #333;
    line-height: 1.6;
    margin-bottom: 10px;
}

.noticiaButton {
    display: inline-block;
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    font-size: 1em;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    transition: background-color 0.3s ease;
    margin-top: 10px;
    text-decoration: none;
}

.noticiaButton:hover {
    background-color: #0056b3;
}

@media (max-width: 768px) {
    .noticiasGrid {
        grid-template-columns: 1fr;
    }

    .noticiaCard {
        margin-bottom: 20px;
    }
}
