/* VideoSec.module.css */
.videoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  gap: 30px;
  padding-bottom: 90px;
}

.videoWrapper {
  flex: 1;
  max-width: 600px;
}

.textWrapper {
  flex: 1;
  text-align: left;
  color: #333;
}

.enrollmentYear {
  font-size: 1.2em;
  color: #ff0000;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.title {
  font-size: 2em;
  font-weight: bold;
  margin-bottom: 10px;
  color: #212e3f;
}

.subtitle {
  font-size: 1.1em;
  color: #333;
  font-weight: 600;
  margin-bottom: 20px;
}

.description {
  font-size: 1em;
  color: #333;
  margin-bottom: 15px;
  line-height: 1.5;
}

.scheduleButton {
  background-color: #28a745;
  /* Verde para o botão */
  color: white;
  padding: 12px 24px;
  font-size: 1.1em;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.scheduleButton:hover {
  background-color: #218838;
}

@media (max-width: 1024px) {
  .scheduleButton {
    padding: 10px 20px;
    font-size: 1em;
  }
}

/* Responsividade para telas menores */
@media (max-width: 768px) {
  .videoContainer {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .textWrapper {
    margin-top: 20px;
    text-align: center;
  }
  .scheduleButton {
    padding: 10px 20px;
    font-size: 0.95em;
  }
}

@media (max-width: 480px) {
  .scheduleButton {
    padding: 10px 20px;
    font-size: 0.85em;
  }
}
