/* CookiesConsent.module.css */
.cookiesConsent {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    background-color: #ffffff;
    color: #333;
    padding: 15px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    z-index: 1000;
    box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.1);
    font-size: 1em;
    border-top: 1px solid #ddd;
    gap: 10px;
}

.messageContainer {
    flex: 1;
    text-align: center;
    max-width: 600px;
    margin-bottom: 10px;
}

.cookiesConsent p {
    margin: 0;
    line-height: 1.5;
    font-size: 0.95em;
}

.cookiesConsent .link {
    color: #4285F4;
    font-weight: bold;
    text-decoration: underline;
}

.acceptButton {
    background-color: #4285F4;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 10px 30px;
    font-size: 0.95em;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.acceptButton:hover {
    background-color: #357ae8;
}

/* Responsividade para telas menores */
@media (max-width: 768px) {
    .cookiesConsent {
        flex-direction: column;
        padding: 10px 15px;
        text-align: center;
    }

    .messageContainer {
        margin: 0 0 10px 0;
    }

    .acceptButton {
        width: 100%;
        max-width: 300px;
        padding: 12px;
    }
}

@media (max-width: 480px) {
    .cookiesConsent {
        padding: 8px 10px;
    }

    .acceptButton {
        width: 100%;
        max-width: 280px;
        padding: 10px;
    }
}
