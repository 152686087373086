.projetosSection {
    padding: 40px;
    background-color: #f4f9ff;
    text-align: center;
}

.projetosSection h2 {
    font-size: 2em;
    font-weight: bold;
    margin-bottom: 10px;
    color: #212e3f;
}

.projetosSlider {
    margin: 20px 0;
}

.slick-dots {
    bottom: -25px; /* Ajuste a posição dos dots */
}

.slick-dots li button:before {
    color: #999; /* Cor padrão dos dots */
    font-size: 10px;
}

.slick-dots li.slick-active button:before {
    color: #fcc101; /* Cor dos dots ativos */
}

.projetoCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    color: inherit;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out;
    padding: 20px;
    height: 100%; /* Certifica que o card ocupa todo o espaço */
    box-sizing: border-box;
    margin-bottom: 40px;
}

.projetoCard:hover {
    transform: scale(1.03);
}

.projetoImage {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 8px 8px 0 0;
    margin-bottom: 15px;
}

/* Placeholder de imagem */
.projetoImagePlaceholder {
    width: 100%;
    height: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #e0e0e0;
    border-radius: 8px 8px 0 0;
    margin-bottom: 15px;
}

.iconPlaceholder {
    color: #b0b0b0;
    font-size: 3em;
}

.projetoInfo {
    display: flex;
    flex-direction: column;
    gap: 8px;
    text-align: left;
    width: 100%;
}

.projetoInfo h3 {
    font-size: 1.2rem;
    color: #333;
    margin: 0;
    font-weight: bold;
}

.projetoSubtitle {
    font-size: 1rem;
    color: #555;
    margin: 0;
}

.projetoDate {
    font-size: 0.85rem;
    color: #888;
    margin: 0;
}

.projetoOverview {
    font-size: 0.9rem;
    color: #666;
    margin: 8px 0;
    text-align: justify;
    line-height: 1.5;
}

.readMore {
    color: #212E3F; /* Cor alinhada com o design */
    cursor: pointer;
    font-weight: bold;
    margin-left: 5px;
    display: inline-block;
    transition: 0.1s;
}

.readMore:hover {
    text-decoration: underline;
    color: #f7c32e; /* Um tom mais escuro para hover */
}


.saibaMaisButton {
    padding: 8px 16px;
    background-color: #fcc101;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 0.85rem;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.saibaMaisButton:hover {
    background-color: #fcc101d5;
}

.verTodosButton {
    display: inline-block;
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #fcc101;
    color: #fff;
    text-decoration: none;
    border-radius: 4px;
    font-weight: bold;
    transition: background-color 0.2s ease;
}

.verTodosButton:hover {
    background-color: #fcc101c5;
}

@media (max-width:700px) {
    .projetosSection {
        padding: 10px;
        background-color: #f4f9ff;
        text-align: center;
    }
}