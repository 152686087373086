.form {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.input, .textarea {
    width: 93%;
    padding: 10px;
    font-size: 1em;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: none;
}

.textarea {
    min-height: 100px;
}

.button {
    padding: 10px;
    background-color: #1ABC9C;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1em;
    transition: background-color 0.3s;
}

.button:hover {
    background-color: #16A085;
}
.fileUpload {
    margin-bottom: 15px;
}

.fileUpload label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
}

.previewImage {
    width: 100%;
    max-width: 300px;
    height: auto;
    margin-top: 10px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.uploadingText {
    color: #007bff;
    font-size: 1em;
    text-align: center;
    margin-bottom: 15px;
}
