.tituloContainer {
    margin-top: 70px;
    margin-bottom: 20px;
    text-align: center;
    background-color: #ffbc0d; /* Cor vermelha de fundo */
    width: 100%;
    min-height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.titulo {
    font-size: 3em;
    font-weight: bold;
    color: #ffffff;
    margin-bottom: 10px;
}

.underline {
    width: 60px;
    height: 3px;
    background-color: #ea4335; /* Cor amarela para a linha abaixo do título */
    margin: 8px auto;
    margin-top: 0px;
    border: none;
}