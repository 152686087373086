.testimonialsContainer {
    background-color: #f4f9ff;
    padding: 50px 20px;
    text-align: center;
    overflow-x: hidden;
}

.title {
    font-size: 2.5em;
    font-weight: bold;
    color: #212e3f;
}

.testimonialsGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    max-width: 1200px;
    margin: 0 auto;
    overflow-x: hidden;
}

.testimonialCard {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    transition: transform 0.3s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    overflow-x: hidden;
}

.testimonialCard:hover {
    transform: translateY(-5px);
}

.testimonialImage {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 15px;
}

.testimonialAuthor {
    font-size: 0.9em;
    color: #ec3237;
    font-weight: bold;
}

.readMore {
    color: #212E3F; /* Cor alinhada com o design */
    cursor: pointer;
    font-weight: bold;
    margin-left: 3px;
    display: inline-block; /* Alinha melhor com o texto */
    transition: 0.1s;
}

.readMore:hover {
    text-decoration: underline;
    color: #f7c32e; /* Um tom mais escuro para o hover */
}

.testimonialText {
    font-size: 1em;
    color: #555;
    margin-bottom: 15px;
    font-style: italic;
    line-height: 1.5; /* Torna o texto mais legível */
    text-align: justify; /* Para um alinhamento mais profissional */
}


/* Responsividade */
@media (max-width: 768px) {

    .testimonialText {
        font-size: 0.95em;
    }

    .testimonialAuthor {
        font-size: 0.85em;
    }
}