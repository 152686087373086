.beneficiosContainer {
    background: linear-gradient(120deg, #f3a41c, #f7c32e, #f3a41c);
    background-size: 200% 200%;
    animation: gradientAnimation 3s ease-in-out infinite;
    padding: 60px 20px;
    text-align: center;
    color: #333;
    position: relative;
    overflow: hidden;
}

/* Animação de gradiente */
@keyframes gradientAnimation {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

/* Onda animada invertida */
.wave {
    position: absolute;
    top: 0;
    left: 0;
    width: 200%;
    height: 150px;
    background: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"%3E%3Cpath fill="%23f7c32e" fill-opacity="1" d="M0,224L48,202.7C96,181,192,139,288,112C384,85,480,75,576,101.3C672,128,768,192,864,192C960,192,1056,128,1152,112C1248,96,1344,128,1392,144L1440,160L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"%3E%3C/path%3E%3C/svg%3E');
    background-size: cover;
    z-index: 1;
    animation: waveAnimation 5s ease-in-out infinite;
    transform: translateY(-80%); /* Posiciona a onda para cima */
}

@keyframes waveAnimation {
    0% { transform: translateX(0); }
    50% { transform: translateX(-50px); }
    100% { transform: translateX(0); }
}

.title {
    font-size: 2.5em;
    color: #ffffff;
    font-weight: bold;
    margin-bottom: 40px;
    text-decoration: underline;
    text-decoration-color: #ea4335;
    text-decoration-thickness: 3px;
    text-underline-offset: 5px;
    position: relative;
    z-index: 2;
}

.beneficiosGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
    z-index: 2;
}

.beneficioCard {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
    transition: transform 0.3s ease;
}

.beneficioCard:hover {
    transform: translateY(-5px);
}

.icon {
    font-size: 2.5em;
    color: #f3a41c;
    margin-bottom: 15px;
}

.beneficioTitle {
    font-size: 1.3em;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
}

.beneficioDescription {
    font-size: 1em;
    color: #555;
    line-height: 1.6;
}

/* Responsividade */
@media (max-width: 768px) {
    .title {
        font-size: 2em;
    }

    .beneficioTitle {
        font-size: 1.2em;
    }

    .beneficioDescription {
        font-size: 0.95em;
    }
}
