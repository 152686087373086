.faqContainer {
    background-color: #f4f9ff;
    padding: 50px 20px;
    max-width: 800px;
    margin: 0 auto;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    text-align: left;
    margin-top: 10px;
    margin-bottom: 20px;
}

.title {
    font-size: 2em;
    font-weight: bold;
    color: #333;
    text-align: center;
    margin-bottom: 30px;
}

.faqList {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.faqItem {
    border-bottom: 1px solid #ddd;
    padding: 10px 0;
}

.question {
    font-size: 1.2em;
    color: #333;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
}

.icon {
    font-size: 1.5em;
    color: #f3a41c;
}

.answer {
    font-size: 1em;
    color: #555;
    margin-top: 10px;
    line-height: 1.6;
    padding-left: 20px;
    transition: max-height 0.3s ease;
}

/* Responsividade */
@media (max-width: 768px) {
    .faqContainer {
        border-radius: 0px;
    }

    .title {
        font-size: 1.8em;
    }

    .question {
        font-size: 1.1em;
    }

    .answer {
        font-size: 0.95em;
    }
}
