/* Container do Hero */
.heroContainer {
    position: relative;
    height: 100vh;
    overflow: hidden;
    background-color: rgb(255, 235, 184); /* Cor de fundo para fallback */
}

/* Cada slide do carrossel */
.slide {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover; /* Garante que a imagem preencha sem distorcer */
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 1s ease-in-out, transform 1s ease;
    opacity: 0; /* Oculta os slides inativos */
    z-index: 0;
    aspect-ratio: 16 / 9; /* Proporção padrão para desktop */
}

/* Slide ativo */
.slide.active {
    opacity: 1; /* Torna visível */
    z-index: 1; /* Garante que o slide ativo fique acima */
}

/* Overlay para escurecer as imagens */
.overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5); /* Sobreposição semi-transparente */
}

/* Texto principal do Hero */
.heroText {
    position: relative;
    color: white;
    font-size: 3.5em;
    font-weight: bold;
    text-align: center;
    max-width: 80%;
    z-index: 1;
    line-height: 1.2;
    padding: 0 20px; /* Margem lateral para ajuste */
    animation: fadeIn 2s ease forwards 1s; /* Efeito de fade-in */
}

/* Animação de fade-in para o texto */
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Responsividade para mobile */
@media (max-width: 800px) {
    .slide {
        aspect-ratio: 3 / 4; /* Proporção ajustada para mobile */
    }

    .heroText {
        font-size: 2.5em; /* Tamanho do texto reduzido */
        line-height: 1.3;
        max-width: 90%;
        padding: 0 15px; /* Reduz margem lateral */
    }
}

@media (max-width: 480px) {
    .heroText {
        font-size: 2em; /* Texto ainda menor para telas menores */
        padding: 0 10px;
    }
}
