/* Container Geral */
.container {
    max-width: 1000px;
    margin: 20px auto;
    padding: 30px;
    background-color: #f8fafc;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

/* Cabeçalho */
.header {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 20px;
}

h2 {
    font-size: 1.8em;
    color: #333;
}

/* Ações do Cabeçalho */
.actionsHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* Campo de Busca */
.searchInput {
    padding: 8px 12px;
    border: 1px solid #ced4da;
    border-radius: 5px;
    font-size: 0.9em;
    width: 250px;
    transition: box-shadow 0.2s;
}

.searchInput:focus {
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.25);
    outline: none;
    border-color: #007bff;
}

/* Botão de Adição */
.addButton {
    padding: 10px 15px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 8px;
    font-size: 0.9em;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.addButton:hover {
    background-color: #0056b3;
}

/* Lista de Notícias */
.noticiasList {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
}

/* Item de Notícia */
.noticiaItem {
    padding: 20px;
    background-color: #ffffff;
    border: 1px solid #e5e7eb;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
    display: flex;
    flex-direction: column;
    transition: box-shadow 0.3s ease;
}

.noticiaItem:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.08);
}

/* Conteúdo de Notícia */
.noticiaContent {
    flex: 1;
}

.noticiaTitle {
    font-size: 1.2em;
    color: #333;
    margin-bottom: 8px;
}

.noticiaDescription {
    color: #666;
    font-size: 0.95em;
    margin-bottom: 12px;
}

.noticiaDate {
    color: #888;
    font-size: 0.85em;
}

.image {
    width: 100%;
    height: auto;
    border-radius: 5px;
    margin: 15px 0;
}

.videoLink {
    color: #007bff;
    font-size: 0.9em;
    margin-top: 10px;
    display: block;
    text-decoration: underline;
}

/* Área de Ações */
.actions {
    display: flex;
    gap: 10px;
    margin-top: 15px;
}

/* Botões de Ação */
.editButton, .deleteButton {
    padding: 8px 16px;
    font-size: 0.9em;
    font-weight: 600;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.editButton {
    background-color: #17a2b8;
}

.deleteButton {
    background-color: #dc3545;
}

.editButton:hover {
    background-color: #138496;
}

.deleteButton:hover {
    background-color: #c82333;
}
