.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px 20px;
    font-family: 'Arial', sans-serif;
    color: #333;
}

.header {
    text-align: center;
    margin-bottom: 20px;
}

.title {
    font-size: 2rem;
    color: #222;
}

.line {
    width: 50px;
    height: 4px;
    background-color: #f7b731;
    margin: 10px auto;
}

.content {
    display: flex;
    gap: 20px;
    margin-bottom: 30px;
    flex-wrap: wrap; /* Permite ajustar o layout em telas menores */
}

.textSection {
    flex: 1;
    line-height: 1.8;
    font-size: 1.1rem;
    min-width: 300px;
}

.textSection p {
    margin-bottom: 20px;
}

.textSection strong {
    color: #f7b731;
}

.imageSection {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 300px;
}

.image {
    max-width: 100%;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.lists {
    display: flex;
    gap: 40px;
    justify-content: space-between;
    flex-wrap: wrap; /* Permite ajustar as listas em telas menores */
}

.listSection {
    flex: 1;
    min-width: 300px;
    margin-bottom: 20px; /* Adiciona espaçamento para evitar sobreposição */
}

.listTitle {
    font-size: 1.2rem;
    margin-bottom: 15px;
    text-transform: uppercase;
    color: #222;
}

.list {
    list-style: none;
    padding: 0;
    line-height: 1.8;
}

.list li {
    position: relative;
    padding-left: 20px;
    margin-bottom: 10px;
    font-size: 1rem;
    color: #555;
}

.list li::before {
    content: '➔';
    position: absolute;
    left: 0;
    color: #f7b731;
    font-weight: bold;
}

/* Media Queries */
@media (max-width: 768px) {
    .content {
        flex-direction: column; /* Alinha a seção de texto e imagem em uma única coluna */
    }

    .textSection, .imageSection {
        flex: none;
        width: 100%; /* Garante que cada seção ocupe toda a largura disponível */
        margin-bottom: 20px;
    }

    .lists {
        flex-direction: column; /* Alinha as listas em uma única coluna */
        gap: 20px;
    }

    .listSection {
        flex: none;
        width: 100%; /* Garante que cada lista ocupe toda a largura disponível */
    }

    .listTitle {
        text-align: center; /* Centraliza o título da lista */
    }
}

@media (max-width: 480px) {
    .title {
        font-size: 1.5rem; /* Reduz o tamanho do título em telas muito pequenas */
    }

    .line {
        width: 40px; /* Ajusta a largura da linha decorativa */
    }

    .list li {
        font-size: 0.9rem; /* Ajusta o tamanho da fonte das listas */
    }
}
