.historiaContainer {
    color: #2c3e50;
    line-height: 1.8;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    padding-bottom: 30px;
}

.content {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
}

.section {
    margin-bottom: 30px;
}

.section p {
    margin: 0;
    text-align: justify;
    font-size: 1.1rem;
    color: #4a4a4a;
}

.imageSection {
    margin-top: 40px;
    text-align: center;
}

.imageContainer {
    margin-bottom: 30px;
    padding: 10px;
    background: #ffffff;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.image {
    width: 100%;
    max-width: 500px;
    display: block;
    margin: 0 auto;
    border-radius: 8px;
    transition: transform 0.3s ease;
}

.image:hover {
    transform: scale(1.03);
}

.caption {
    font-size: 0.95rem;
    color: #7f8c8d;
    margin-top: 12px;
    font-style: italic;
    text-decoration: underline;
}

.TituloComponent {
    font-size: 2.5rem;
    text-align: center;
    color: #4caf50;
    margin-bottom: 40px;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.buttonContainer {
    text-align: center;
    margin-top: 30px;
}

.buttonInfo {
    font-size: 1em;
    color: #ff0000;
    /* Texto em vermelho para destaque */
    font-weight: bold;
    margin-bottom: 20px;
}

.actionButton {
    background-color: #28a745;
    /* Verde para o botão */
    color: white;
    padding: 12px 24px;
    font-size: 1.1em;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.actionButton:hover {
    background-color: #218838;
    /* Escurece o verde ao passar o mouse */
}