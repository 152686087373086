.projetoFull {
    width: 100%;
    background-color: #f4f7fa;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-top: 80px;
}

.projetoContainer {
    background-color: #f4f7fa;
    padding: 40px;
    max-width: 900px;
    margin: 0 auto;
    border-radius: 5px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
}

.projetoTitulo {
    font-size: 2.2em;
    font-weight: bold;
    color: #2a3b5c;
    margin-bottom: 5px;
    text-align: center;
    border-bottom: 2px solid #ffbf00;
    padding-bottom: 8px;
}

.projetoData {
    font-size: 0.9em;
    color: #777;
    margin-bottom: 25px;
    text-align: center;
    font-style: italic;
}

.projetoImagemContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
}

.projetoImagem {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
}

.projetoImagem:hover {
    transform: scale(1.05);
}

.projetoImagemPlaceholder {
    width: 100%;
    max-width: 600px;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #e6e9ef;
    border-radius: 10px;
    box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease;
}

.projetoImagemPlaceholder:hover {
    background-color: #d0d4db;
}

.iconPlaceholder {
    color: #a0a5ad;
    font-size: 4em;
}

.projetoSubtitulo {
    font-size: 1.7em;
    color: #4a5568;
    margin-bottom: 20px;
    text-align: center;
    font-weight: 600;
}

.projetoDescricao {
    font-size: 1em;
    color: #4a4a4a;
    margin-bottom: 20px;
    background: #ffffff;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
}

.projetoTexto {
    font-size: 1em;
    color: #333;
    line-height: 1.8;
    padding: 15px;
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
}

.loading, .notFound {
    font-size: 1.2em;
    color: #555;
    text-align: center;
    margin: 40px auto;
}

@media (max-width:850px) {
    .projetoFull {
        width: 100%;
        background-color: #f4f7fa;
        padding-top: 0px;
        padding-bottom: 0px;
        margin-top: 75px;
    }

    .projetoContainer {
        background-color: #f4f7fa;
        padding: 40px;
        max-width: 900px;
        margin: 0 auto;
        border-radius: none;
        box-shadow: none;
    }
}