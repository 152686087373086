.hinoContainer {
    line-height: 1.8;
    background-color: #fdfdfd;
    border-radius: 8px;
    margin: 20px auto;
}

.content {
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
}

.section {
    margin-bottom: 30px;
}

.lyrics {
    margin: 20px 0;
    line-height: 2;
    text-align: justify;
}

.lyrics p {
    text-align: center;
    margin: 10px 0;
    font-size: 1.2rem;
}

.refrain {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 1.3rem;
    text-align: center;
    color: #d32f2f;
    font-weight: bold;
}

.authors {
    margin-top: 20px;
    font-size: 1rem;
    color: #555;
    text-align: center;
}

.audioSection {
    margin-top: 30px;
    text-align: center;
}

audio {
    width: 100%;
    max-width: 600px;
    outline: none;
}
