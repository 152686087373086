/* Login.module.css */

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f2f5;
  }
  
  .form {
    background-color: #fff;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
    box-sizing: border-box;
  }
  
  .form h2 {
    margin-bottom: 30px;
    text-align: center;
    color: #333;
    font-size: 24px;
  }
  
  .inputGroup {
    margin-bottom: 20px;
  }
  
  .inputGroup label {
    display: block;
    margin-bottom: 8px;
    color: #555;
    font-size: 14px;
  }
  
  .inputGroup input {
    width: 100%;
    padding: 12px 15px;
    border: 1px solid #ccc;
    border-radius: 6px;
    font-size: 16px;
    transition: border-color 0.3s;
  }
  
  .inputGroup input:focus {
    border-color: #3f51b5;
    outline: none;
  }
  
  .button {
    width: 100%;
    padding: 14px;
    background-color: #3f51b5;
    color: #fff;
    border: none;
    border-radius: 6px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .button:hover {
    background-color: #303f9f;
  }
  
  .error {
    color: red;
    margin-bottom: 20px;
    text-align: center;
    font-size: 14px;
  }
  
  @media (max-width: 480px) {
    .form {
      padding: 30px 20px;
    }
  
    .form h2 {
      font-size: 20px;
    }
  
    .button {
      font-size: 16px;
      padding: 12px;
    }
  }
  