.container {
    margin: 0 auto;
    padding: 20px;
    max-width: 800px;
}

.section {
    margin-bottom: 20px;
    padding: 10px;
    border-bottom: 1px solid #ccc;
}

.sectionTitle {
    font-size: 1.5rem;
    color: #1c2a5b;
    margin-bottom: 10px;
    font-weight: bold;
}

.container ul {
    list-style: none;
    padding: 0;
}

.container ul li {
    margin-bottom: 5px;
    font-size: 1rem;
}

.contactButtonContainer {
    text-align: center;
    margin-top: 20px;
}

.contactButton {
    background-color: #0056b3;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.contactButton:hover {
    background-color: #003f88;
}
