.overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed; /* Asegura-se de cobrir toda a tela */
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7); /* Background semitransparente */
    z-index: 9999;

}

.modalContent {
    background-color: #ffffff;
    padding: 30px;
    width: 100%;
    max-width: 500px;
    border-radius: 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 20px;
    color: #ff0000;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.5);
}

.closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 1.5rem;
    color: white;
    cursor: pointer;
}

.content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-top: 50px;
}

.closeButton {
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 1.5rem;
    color: #ffd700;
    cursor: pointer;
    padding: 3px;
    border-radius: 50%;
}

.closeButton:hover {
    transition: 1s;
    background-color: rgba(255, 151, 151, 0.151);
}

@keyframes spin-forward {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(200deg);
    }
}

@keyframes spin-backward {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(-200deg);
    }
}

.closeButton.spinForward {
    animation: spin-forward 0.4s ease-in-out;
}

.closeButton.spinBackward {
    animation: spin-backward 0.4s ease-in-out;
}

@media (max-width:800px) {
    .modalContent {
        padding: 10px;
        padding-top: 0px;
        width: 100%;
        height: 100%;
        max-width: auto;
        border-radius: 0px;
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 20px;
        color: #ff0000;
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.5);
    }
}